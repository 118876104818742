import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const HeaderLink: React.FC<HeaderLinkProps> = ({
  className = "",
  to,
  children,
}) => (
  <Link
    className={`px-2 h-8 flex items-center transition-colors duration-150 ease-in-out hover:text-red-400 md:h-full dark:text-gray-50 ${className}`}
    to={to}
    activeClassName="text-red-400"
  >
    {children}
  </Link>
);

HeaderLink.displayName = "HeaderLink";

HeaderLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default HeaderLink;

export type HeaderLinkProps = {
  className?: string;
  to: string;
  children: React.ReactNode;
};
