import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

const Footer: React.FC<FooterProps> = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: { draft: { ne: true } }
          fileAbsolutePath: { regex: "/posts/.*\\\\.md$/" }
        }
        sort: { fields: [frontmatter___date] }
        limit: 1
      ) {
        edges {
          node {
            frontmatter {
              date(formatString: "YYYY")
            }
          }
        }
      }
    }
  `);
  const copyrightStartYear =
    data.allMarkdownRemark.edges.length > 0
      ? data.allMarkdownRemark.edges[0].node.frontmatter.date
      : new Date().getFullYear().toString();
  const copyrightEndYear = new Date().getFullYear().toString();
  const copyrightText =
    copyrightStartYear === copyrightEndYear
      ? copyrightStartYear
      : `${copyrightStartYear}-${copyrightEndYear}`;
  return (
    <footer className="h-24 flex flex-col justify-center items-center bg-gray-50 border-t border-gray-200 shadow-footer dark:bg-gray-800 dark:border-gray-600">
      <p className="my-0.5 mx-auto text-center text-xs text-gray-600 transition-colors duration-150 ease-in-out select-none hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200">
        © {copyrightText} Copyright Luyu Cheng. All Rights Reserved.
      </p>
      <p className="my-0.5 mx-auto text-center text-xs text-gray-600 transition-colors duration-150 ease-in-out select-none hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200">
        Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>, React, Node.js, and many
        other open source tools.
      </p>
    </footer>
  );
};

Footer.displayName = "Footer";

Footer.propTypes = {
  children: PropTypes.node,
  siteTitle: PropTypes.string,
};

export default Footer;

export type FooterProps = React.PropsWithChildren<{ siteTitle?: string }>;
