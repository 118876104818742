import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import HamburgerButton from "./HamburgerButton";
import HeaderLink from "./HeaderLink";

const Header: React.FC<HeaderProps> = ({ siteTitle = "" }) => {
  const [show, setShow] = useState(false);
  return (
    <header className="w-full px-6 bg-gray-50 border-b border-gray-300 shadow-header overflow-hidden dark:bg-gray-800 dark:border-gray-600">
      <main className="container mx-auto max-w-screen-lg flex flex-col items-center md:flex-row">
        <div className="w-full h-16 flex justify-between items-center md:w-max">
          <HeaderLink className="h-full pr-2 text-lg font-medium" to="/">
            {siteTitle}
          </HeaderLink>
          <HamburgerButton opened={show} onClick={() => setShow(!show)} />
        </div>
        <nav
          className={`pb-2 flex flex-col justify-center items-center md:flex-row md:justify-start md:pb-0 md:h-16 ml-6 ${
            show ? "" : "hidden md:flex"
          }`}
        >
          <HeaderLink to="/posts">Posts</HeaderLink>
          <HeaderLink to="/projects">Projects</HeaderLink>
          <HeaderLink to="/gallery">Gallery</HeaderLink>
          <HeaderLink to="/bookmarks">Bookmarks</HeaderLink>
          <HeaderLink to="/friends">Friends</HeaderLink>
          <HeaderLink to="/about">About</HeaderLink>
        </nav>
      </main>
    </header>
  );
};

Header.displayName = "Header";

Header.propTypes = {
  siteTitle: PropTypes.string,
};

export default Header;

export type HeaderProps = React.PropsWithChildren<{ siteTitle?: string }>;
