/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";
import Footer from "./Footer";
import "normalize.css";
import "./Layout.scss";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="pt-4 px-5 pb-6 min-h-content">
        <main className="mx-auto max-w-screen-lg">{children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.displayName = "Layout";

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

export type LayoutProps = React.PropsWithChildren<{}>;
